import * as React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import Text from "../../components/global/text";
import Develop from "../../components/global/develop";
import HeaderGlobal from "../../components/headers/header-global";
import { graphql } from "gatsby";
import Process from "../../components/global/process";
import PromiseOne from "../../assets/images/other-services/ai.svg";
import PromiseTwo from "../../assets/images/other-services/decisions.svg";
import PromiseThree from "../../assets/images/other-services/integration.svg";
import Promise from "../../components/global/promise";
import HeaderBcg from "../../assets/images/headers/semantic.png";
import SemanticImg from "../../assets/images/other-services/insights.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import FeatureOne from "../../assets/images/other-services/data-validation.svg";
import FeatureThree from "../../assets/images/other-services/data-sources.svg";
import FeatureFour from "../../assets/images/other-services/ontology.svg";
import FeatureFive from "../../assets/images/other-services/internet-data.svg";
import FeatureSix from "../../assets/images/other-services/reasoning.svg";
import FeatureSeven from "../../assets/images/other-services/models.svg";
import Projects from "../../components/global/projects";

const EngineeringTeam = () => {
  const { t } = useTranslation();
  // Header Content
  const hTitle = `${t("services.semantic.headline")}`;
  const hTextOne = `${t("services.semantic.firstText")}`;

  const textContent2 = {
    title: `${t("services.semantic.ourApproach.headline")}`,
    text: `${t("services.semantic.ourApproach.text")}`,
  };

  const promiseHeadline = `${t("services.semantic.promise.headline")}`;
  // Promise Component
  const promiseContent = [
    {
      title: `${t("services.semantic.promise.first.title")}`,
      textOne: `${t("services.semantic.promise.first.firstText")}`,
      icon: <PromiseOne />,
    },
    {
      title: `${t("services.semantic.promise.second.title")}`,
      textOne: `${t("services.semantic.promise.second.firstText")}`,
      icon: <PromiseTwo />,
    },
    {
      title: `${t("services.semantic.promise.third.title")}`,
      textOne: `${t("services.semantic.promise.third.firstText")}`,
      icon: <PromiseThree />,
    },
  ];

  const insightsList = [
    {
      item: `${t("services.semantic.insights.list.firstItem")}`,
    },
    {
      item: `${t("services.semantic.insights.list.secondItem")}`,
    },
    {
      item: `${t("services.semantic.insights.list.thirdItem")}`,
    },
    {
      item: `${t("services.semantic.insights.list.fourthItem")}`,
    },
    {
      item: `${t("services.semantic.insights.list.fifthItem")}`,
    },
    {
      item: `${t("services.semantic.insights.list.sixhtItem")}`,
    },
  ];

  // Process Component
  const processShortText = `${t("services.semantic.process.shortText")}`;

  const processContent = [
    {
      title: `${t("services.semantic.process.first.title")}`,
      text: `${t("services.semantic.process.first.text")}`,
    },
    {
      title: `${t("services.semantic.process.second.title")}`,
      text: `${t("services.semantic.process.second.text")}`,
    },
    {
      title: `${t("services.semantic.process.third.title")}`,
      text: `${t("services.semantic.process.third.text")}`,
    },
    {
      title: `${t("services.semantic.process.fourth.title")}`,
      text: `${t("services.semantic.process.fourth.text")}`,
    },
  ];

  const features = [
    {
      id: 1,
      title: `${t("services.semantic.features.featureOne")}`,
      icon: <FeatureOne />,
    },
    {
      id: 2,
      title: `${t("services.semantic.features.featureTwo")}`,
      icon: <PromiseTwo />,
    },
    {
      id: 3,
      title: `${t("services.semantic.features.featureThree")}`,
      icon: <FeatureThree />,
    },
    {
      id: 4,
      title: `${t("services.semantic.features.featureFour")}`,
      icon: <FeatureFour />,
    },
    {
      id: 5,
      title: `${t("services.semantic.features.featureFive")}`,
      icon: <FeatureFive />,
    },
    {
      id: 6,
      title: `${t("services.semantic.features.featureSix")}`,
      icon: <FeatureSix />,
    },
    {
      id: 7,
      title: `${t("services.semantic.features.featureSeven")}`,
      icon: <FeatureSeven />,
    },
  ];
  return (
    <Layout>
      <Seo title={t("navigation.services.semantic")} />
      <HeaderGlobal hTitle={hTitle} hTextOne={hTextOne} image={HeaderBcg} />
      <Promise
        promiseContent={promiseContent}
        promiseHeadline={promiseHeadline}
        differentStyle="-promiseTitle -semantic"
      />
      <section className="m-semantic mt-40">
        <div className="section-padding">
          <div className="_wr">
            <div className="tac">
              <h2>{t("services.semantic.insights.title")}</h2>
              <span className="a-line -red"></span>
            </div>
            <div className="flex justify-center m-semantic__insightsImg mt-60">
              <img src={SemanticImg} alt="Features" />
            </div>
            <div className="m-semantic__insight _w">
              <div className="_l6">
                <p>{t("services.semantic.insights.firstText")}</p>
                <p className="mt-15">
                  {t("services.semantic.insights.secondText")}
                </p>
              </div>
              <div className="_l6">
                <p className="m-semantic__listTitle">
                  {t("services.semantic.insights.listTitle")}
                </p>
                <ul className="mt-15 m-list -bullet">
                  {insightsList.map(({ item }, key) => (
                    <li key={key}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="m-semantic__features">
          <div className="_wr">
            <div className="_w">
              <div className="m-semantic__features--header">
                <h2>{t("services.semantic.sectionTitle")}</h2>
                <span className="a-line -red"></span>
              </div>
              {features.map((feature) => {
                const { title, icon } = feature;
                return (
                  <div className="_m6 _l4 _xl3">
                    <div className="m-semantic__features--feature">
                      {icon}
                      <p>{title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Text
        textContent={textContent2}
        backgroundColor="-approach -semantic"
        cssModifer="-homeText"
      />
      <Process
        processContent={processContent}
        processShortText={processShortText}
        backgroundColor="-borderBlack -semantic -process"
      />
      <Projects />
      <Develop cssModifer="-whiteBg" />
    </Layout>
  );
};

export default EngineeringTeam;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
